import React from 'react'
import { Link } from 'gatsby'

import { isFutureDate } from '~/libs/es/helper'
import { displayPrice } from '~/libs/es/formatter'
import { classDisplayDate, classDisplayDayQty } from '~/libs/es/helper-class'

export default function ({ item }) {
    const { context: { title, to, classDate, classPrice } } = item

    const isBook = isFutureDate(classDate[0], -2)

    return <>
        <Link to={to}>
            <div className="card" style={{ margin: '10px' }}>
                <div className="card-content">
                    <p className="title is-4">{title}</p>
                    <p><span className="tag is-info">คอร์สอบรม</span>{' '}{isBook && <span className="tag is-danger">ลงทะเบียนด่วน</span>}</p>
                    <p style={{ marginTop: '10px' }}>วันที่เปิดอบรม: <b>{classDisplayDate(classDate)}</b></p>
                    <p>จำนวนวันอบรม: <b>{classDisplayDayQty(classDate)} วัน</b></p>
                    <p>ค่าอบรม: <b>{displayPrice(classPrice[0])} บาท</b></p>
                </div>
                <footer className="card-footer">
                    <p className="card-footer-item">
                        <span>รายละเอียด ...</span>
                    </p>
                </footer>
            </div>
        </Link>
    </>
}