import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { shuffle } from '~/libs/es/helper'
import { classFilterDate, classSortByDate } from '~/libs/es/helper-class'

import ClassListItem from 'components/class-list-item'
import CourseListItem from 'components/course-list-item'
import ArticleListItem from 'components/article-list-item'

export default function ({ pageContext, data }) {
    const { allCourse: { courses }, allClass: { classes }, allArticle: { articles } } = data

    const items = [
        ...articles,
        ...courses
    ]

    return <>
        <Helmet>
            <title>{`${pageContext.siteDescription} | ${pageContext.siteTitle}`}</title>
        </Helmet>

        {
            classes
                .filter(classFilterDate)
                .sort(classSortByDate)
                .map(each => {
                    return <ClassListItem key={each.id} item={each} />
                })
        }

        {
            shuffle(items)
                .map(each => {
                    return /article\//.test(each.context.to)
                        ? <ArticleListItem key={each.context.id} item={each} />
                        : <CourseListItem key={each.context.id} item={each} />
                })
        }
    </>
}

export const query = graphql`
    query {
        allClass: allSitePage(filter: {path: {regex: "/class/\\w+/"}}) {
            classes: nodes {
                context {
                    id
                    title
                    to
                    classDate
                    classPrice
                }
            }
        }

        allCourse: allSitePage(filter: {path: {regex: "/course/\\w+/"}}) {
            courses: nodes {
                context {
                    id
                    title
                    to
                    courseDuration
                }
            }
        }

        allArticle: allSitePage(limit: 5, filter: {path: {regex: "/article/\\w+/"}, context: {title: {ne: null}}}, sort: {fields: context___articleDate, order: DESC}) {
            articles: nodes {
                context {
                    id
                    title
                    to
                    excerpt
                    heroImage
                    articleDate
                    categories
                }
            }
        }
    }
`