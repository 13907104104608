import { isFutureDate } from '~/libs/es/helper'
import { displayDate } from '~/libs/es/formatter'

export function classFilterDate(each) {
    const { context: { classDate } } = each

    return isFutureDate(classDate[0], -2)
}

export function classSortByDate(next, prev) {
    const { context: { classDate: nextClassDate } } = next
    const { context: { classDate: prevClassDate } } = prev

    return Date.parse(prevClassDate[0]) - Date.parse(nextClassDate[0])
}

export function classDisplayDate(date) {
    if (!isFutureDate(date[0], -2)) {
        return 'เร็วๆนี้'
    }


    const pattern = /(\S{2,3})\040(\d{2})\040(\S{4,5})\040(\d{4})/

    const [date1, wd1, d1, m1, y1] = displayDate(date[0], true).match(pattern)
    const [date2, wd2, d2, m2, y2] = date[1] ? displayDate(date[1], true).match(pattern) : []

    const wd = !date2 ? `(${wd1})` : `(${wd1}-${wd2})`

    if (!date2) {
        return `${wd} ${d1} ${m1} ${y1}`
    }

    if (m1 === m2) {
        return `${wd} ${d1}-${d2} ${m1} ${y1}`
    }

    if (y1 === y2) {
        `${wd} ${d1} ${m1}-${d2} ${m2} ${y1}`
    }

    return ` ${wd}${d1} ${m1} ${y1}-${d2} ${m2} ${y2}`
}

export function classDisplayDayQty(date) {
    if (!date[1]) {
        return 1
    }

    const oneDay = 24 * 60 * 60 * 1000
    const d1 = Date.parse(date[0])
    const d2 = Date.parse(date[1])

    return (d2 - d1) / oneDay + 1
}