import React from 'react'
import { Link } from 'gatsby'

export default function ({ item }) {
    const { context: { title, to, courseDuration } } = item

    return <>
        <Link to={to}>
            <div className="card" style={{ margin: '10px' }}>
                <div className="card-content">
                    <p className="title is-4">{title}</p>
                    <p><span className="tag is-success">คอร์สอบรมองค์กร</span></p>
                    <p style={{ marginTop: '10px' }}>ระยะเวลาของหลักสูตร: <b>{courseDuration}</b></p>
                </div>
                <footer className="card-footer">
                    <p className="card-footer-item">
                        <span>รายละเอียด ...</span>
                    </p>
                </footer>
            </div>
        </Link>
    </>
}